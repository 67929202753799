import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addVideoSection, updateContent } from '../../actions/Events';
import { DeleteIcon } from '../shared/DeleteIcon';
import { EditableTextInput } from '../shared/TextInput';
import { Flexbox } from '../shared/Flexbox';
import Button from '@mui/material/Button';
import { useParams, withRouter } from 'react-router';
import { FirebaseContext } from '../../contexts/firebase';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  mapArrayToObject,
  mapObjectArrayToArray,
  walkArraysToObjectArrays,
  walkObjArraysToArrays,
} from '../../utils/objectArrays';
import Section from './VideoSection';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { map } from 'lodash';

const langOptions = [
  { label: 'None', id: 'none' },
  { label: 'English', id: 'english' },
  { label: 'Spanish', id: 'spanish' },
];

const _VideoContent = ({ contentId, content, onUpdateContent }) => {
  const { clientId, eventId, carrierId } = useParams();
  const { firebase, database } = useContext(FirebaseContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = Boolean(anchorEl);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: 'video-content-' + contentId,
  });

  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (content.sections) {
      setSections(
        walkObjArraysToArrays(content.sections).map((s, i) => {
          return {
            ...s,
            items: s.items
              ? walkObjArraysToArrays(s.items).map((item, idx) => {
                  return {
                    item,
                    id: `item-${contentId}-${i}-${idx}`,
                  };
                })
              : [],
            id: `section-${contentId}-${i}`,
          };
        }),
      );
    }
  }, [content.sections]);

  const handleDragEnd = event => {
    const { active, over } = event;
    console.log({ active, over });

    setSections(section => {
      const oldIndex = section.findIndex(c => c.id === active.id);
      const newIndex = section.findIndex(c => c.id === over.id);

      const newArray = arrayMove(sections, oldIndex, newIndex);

      console.log({ newArray });
      onUpdateContent(clientId, eventId, carrierId, {
        [`videoContent.${contentId}.sections`]: mapArrayToObject(
          newArray.map(s => {
            const { id, ...section } = s;
            return section;
          }),
        ),
      });

      return newArray;
    });
  };

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      ref={setNodeRef}
      sx={{
        transform: CSS.Translate.toString(transform),
        transition,
        width: '100%',
        zIndex: isDragging ? 1000 : 'auto',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <IconButton
              {...listeners}
              {...attributes}
              aria-label="drag"
              size="small"
            >
              <DragIndicatorIcon color="primary" fontSize="inherit" />
            </IconButton>

            <Box sx={{ display: 'flex' }}>
              <Typography variant="body2">{content.title}</Typography>
              <Typography variant="caption">({content.language})</Typography>
            </Box>
          </Box>

          <IconButton
            aria-label="drag"
            size="small"
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon color="secondary" />
          </IconButton>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={showMenu}
          onClose={() => setAnchorEl(null)}
        >
          <MenuList dense>
            <MenuItem>Delete</MenuItem>
          </MenuList>
        </Menu>
      </AccordionSummary>

      <AccordionDetails>
        <DeleteIcon
          onClick={() =>
            onUpdateContent(clientId, eventId, carrierId, {
              [`videoContent.${contentId}`]:
                firebase.firestore.FieldValue.delete(),
            })
          }
        />

        <FormControl>
          <Stack spacing={2}>
            <Autocomplete
              size="small"
              id="language"
              autoHighlight
              disablePortal
              options={langOptions}
              renderInput={params => (
                <TextField {...params} variant="filled" label="Language" />
              )}
              value={
                langOptions.filter(l => l.id === content?.language)[0] ||
                langOptions[0]
              }
              onChange={(event, newValue) => {
                onUpdateContent(clientId, eventId, carrierId, {
                  [`videoContent.${contentId}.language`]:
                    newValue.id === 'none'
                      ? firebase.firestore.FieldValue.delete()
                      : newValue.id,
                });
              }}
            />

            <EditableTextInput
              label="Title"
              defaultValue={content.title}
              onChange={newValue => {
                onUpdateContent(clientId, eventId, carrierId, {
                  ['videoContent.' + contentId + '.title']: newValue,
                });
              }}
            />
          </Stack>
        </FormControl>

        <Flexbox>
          <div>Sections</div>
        </Flexbox>
        <Box>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={sections}
              strategy={verticalListSortingStrategy}
            >
              <Stack spacing={2}>
                {sections.map((s, i) => {
                  return (
                    <Section
                      key={i}
                      contentId={contentId}
                      sectionId={i}
                      section={s}
                    />
                  );
                })}
              </Stack>
            </SortableContext>
          </DndContext>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={async event => {
              event.stopPropagation();

              const ref = database.doc(
                `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`,
              );
              const doc = await ref.get();

              const data = walkObjArraysToArrays(doc.data());
              data.videoContent[contentId].sections.push({ items: [] });

              await ref.update({
                [`videoContent.${contentId}.sections`]:
                  walkArraysToObjectArrays(
                    data.videoContent[contentId].sections,
                  ),
              });
            }}
          >
            Add Section
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
export const VideoContent = withRouter(
  connect(
    (state, ownProps) => {
      return {
        sections: ownProps.content.sections,
      };
    },
    dispatch => ({
      onAddSection: (...args) => dispatch(addVideoSection(...args)),
      onUpdateContent: (...args) => dispatch(updateContent(...args)),
    }),
    null,
    {
      areOwnPropsEqual: () => false,
    },
  )(_VideoContent),
);
